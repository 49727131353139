.analyserTable {
  border-collapse: collapse;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-bottom: solid 1px;
}

.filter-class {
  width: 330px;
  min-width: 330px
}

.popover-box {
  padding: 10px;
}

.analyserTable td, .analyserTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.analyserTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.analyserTable tr:hover {
  background-color: #ddd;
}

.analyserTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.filter {
  position: fixed;
  top: 90px;
  left: 100px;
  width: 260px;
  height: 10px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-container {
  display: flex;
  gap: 10px;
}

.inner-div {
  position: relative;
  width: 100%;
  background-color: #ddd;
}

.inner-div .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ddd;
  border-color: #ddd;
}

.tableHdr {
  border-bottom: 1px solid;
  color: black;
  font-weight: bold
}

.tableHdr:nth-child(2) > .sort {
  display: none;
}

.tableHdr:nth-child(3) > .sort {
  display: none;
}

.tableHdr.headerbox:hover {
  cursor: auto;
}

