.btn-visible,
.btn-invisible {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12.15%;
  width: 100%;
  transition: 0.3s ease-in-out;
  background-color: #ffebfe !important;
  z-index: 102;
}

.btn-invisible {
  z-index: 2;
}

.btn-visible::after,
.btn-invisible::after {
  content: "";
  border-radius: 0 5px 5px 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 110%;
  position: absolute;
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 8%, rgba(255, 208, 253, 1) 8%, rgba(255, 208, 253, 1) 88%);
  transition: 0.3s ease-in-out;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
  z-index: 103;
}

.btn-invisible::after {
  left: -100%;
  width: 100%;
  box-shadow: none;
}

.btn-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  box-shadow: 0px 0px 0px 5px rgba(255, 208, 253, 1) inset;
}

.btn-box-inner::after {
  content: "";
  position: absolute;
  left: 80%;
  top: 80%;
  height: 75%;
  transform-origin: center;
  width: 50%;
  transform: rotate(45deg);
  background-color: #9C27B0;
  outline: solid 15px #FFD0FD;
  box-shadow: 0px 0px 0px 15px rgb(204, 168, 253) inset;
}

.info-box-slide-visible,
.info-box-slide-invisible {
  border-radius: 10px;
  height: 200px;
  left: 120%;
  width: 400px;
  position: absolute;
  opacity: 1;
  transition: opacity 0.3s;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

.info-box-slide-invisible {
  display: none;
  opacity: 0 !important;
  transition: 0.2s;
}

.tool-button-text {
  padding-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 104;
}

.info-tab-helper-strip {
  height: 100%;
  position: absolute;
  left: 100%;
}
