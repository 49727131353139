.links-container {
  height: auto;
  display: flex;
  align-items: center;
  background: fixed linear-gradient(315deg, rgba(156, 39, 176, 1) 0%, rgba(156, 39, 176, 1) 33%, rgba(204, 168, 253, 1) 33%, rgba(204, 168, 253, 1) 67%, rgba(255, 208, 253, 1) 67%, rgba(255, 208, 253, 1) 100%);
  justify-content: center;
  border-radius: 50px;
  flex-direction: column;
  padding: 7px;
  margin-bottom: 50px;
}

.link-anchor-gap {
  height: 70px;
}

.links-treeview-container {
  display: flex;
  background-color: white;
  border-radius: 45px;
  height: auto;
}

.links-container-sticky {
  position: sticky;
  height: 100%;
  width: 25%;
  margin-top: 20px;
  top: 50px;
}

.links-tree-view-right-border {
  width: 100%;
  height: auto;
  margin-left: 20px;
  border-right: solid 1px #FFD0FD;
  margin-top: 100px;
}

.link-expand-icon {
  padding: 6px;
  font-size: 2rem !important;
}

.treeitem-label {
  font-weight: 600;
  font-size: 16px;
  color: #1B1B1B;
  text-decoration: none;
  margin: 0.25rem !important;
}

.treeitem-label:hover,
.treeitem-label:active {
  color: #9C27B0;
  text-decoration: none;
}

.link-container-right {
  padding: 0 50px 50px;
  width: 75%;
  height: auto;
  margin-bottom: 50px;
}
