.textInputContainer {
  display: block;
  margin-bottom: 10px;
}

.textInput {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: solid 1px black;
  resize: none;
  overflow-y: auto;
}

.textInputDiv {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  border: solid 1px black;
  padding: 10px;
  margin-bottom: 10px;
  cursor: default;
}

.wordHighlightButtons {
  position: relative;
  left: 85%;
  height: 0;
  display: flex;
}

.analyseBtn {
  margin-left: 50px;
}

.lemmaTable {
  width: 500px;
  margin-top: 20px;
  border-collapse: collapse;
}

.lemmaTable th, td {
  padding: 5px 15px;
}

.lemmaTable td:nth-of-type(3) {
  text-align: center;
}

.lemmaTable td:nth-of-type(4) {
  text-align: center;
}

.marked {
  background-color: #FFFF00;
}

.blue {
  background-color: #02a5de;
}

.word {
  cursor: pointer;
  white-space: nowrap;
}

.wordInfo {
  line-height: 1em;
  max-width: 80%;
}

.textTooLongInfobox {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
