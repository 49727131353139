.footer-container {
  width: 100%;
  height: auto;
  text-align: center;
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 35%, rgba(255, 208, 253, 1) 35%, rgba(255, 208, 253, 1) 55%, rgba(255, 255, 255, 1) 55%, rgba(255, 255, 255, 1) 100%);
  margin-top: 0;
}

.footer-inner {
  width: 100%;
  padding-left: 6rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4rem;
}

.footer-inner-right {
  display: flex;
  flex: 3;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4rem;
}

.footer-logo-box {
  flex: 2;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.dti-logo {
  height: 8rem;
  margin-bottom: 2rem;
}

.footer-middle-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
}

.footer-box-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: start;
  padding-right: 4rem;
}

.footer-version {
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  color: #0009;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.footer-version.no-link {
  text-decoration: none;
}

@media (max-width: 1024px) {
  .footer-inner {
    padding-left: 2rem;
    padding-top: 1rem;
  }

  .footer-inner-right {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .footer-logo-box {
    padding-left: 0;
    align-items: center;
  }

  .footer-box-right {
    padding-right: 0;
    justify-content: flex-start;
    align-items: center;
  }

  .footer-middle-box, .footer-box-right {
    align-items: center;
    text-align: center;
  }
}
