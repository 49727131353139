@import "~@fortawesome/fontawesome-free/css/all.css";
@import url('https://fonts.googleapis.com/css?family=Mulish');
@import url('https://fonts.googleapis.com/css?family=Exo+2');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons');

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "Mulish", sans-serif;
  background: linear-gradient(180deg, rgba(238, 174, 202, 0.2) 0%, rgba(148, 187, 233, 0.2) 100%);
}

h2 {
  font-family: "Exo 2", sans-serif;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f6f6f6;
}

@media only screen and (min-width: 992px) {
  .lang-dropdown {
    position: absolute;
    right: 1em;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #9C27B0;
}

.elle-dark-text {
  color: #9C27B0;
}

.elle-medium-text {
  color: #CCA8FD;
}

.tooltip-icon {
  margin-left: 0.25em;
  color: #3B3B3B;
  border: 2.5px solid #3B3B3B;
  border-radius: 12px;
  padding: 1px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #9C27B0 !important;
}

.MuiInputLabel-root.Mui-focused,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-root.Mui-checked {
  color: #9C27B0 !important;
}


.MuiMenuItem-root.Mui-selected {
  background-color: rgba(255, 208, 253, .5) !important;
}

.MuiAlert-standardInfo {
  background-color: rgba(255, 208, 253, .5) !important;
}

.MuiAlert-standardInfo .MuiAlert-icon {
  color: #9C27B0 !important;
}
