.alertBox {
  padding: 0 10px;
  width: 65%;
  border-radius: 5px;
  background-color: #E1F5FE;
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
}

.wordAnalyserBox {
  margin-bottom: -3rem;
}
