.breadcrumb-box {
  display: flex;
  width: 80vw;
  padding: 25px;
  align-items: flex-end;
}

.breadcrumb-menu-link {
  padding-right: 10px;
}

.breadcrumb-menu-link.regular {
  padding-left: 10px;
}
