
.queryContainer {
  display: flex;
}

.queryContainer > div {
  flex: 1;
}

.corpustitle {
  position: relative;
  border-bottom: 1px dotted black;
}

.queryAccordion {
  margin-top: 1em !important;
  border-radius: 4px 4px 0 0 !important;
}

.buttonBox {
  background-color: black;
  top: 50px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: auto;
  max-width: 0px;
  margin-left: 50px;
}

.buttonRight {
  margin-left: auto !important;
}

.buttonSecondLeft {
  margin-left: 1em !important;
}

.query-textinput {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: solid 1px black;
  resize: none;
  overflow-y: auto;
}

.selected-text-chip {
  margin-left: 0.5em !important;
}

.query-own-texts-modal {
  width: 700px !important;
  height: 375px !important;
  background-color: white;
  border: solid #9C27B0 4px;
  border-radius: 25px;
  padding: 20px;
  overflow: hidden;
  transition: 0.2s;
}

.query-own-texts-modal-closed {
  width: 0 !important;
  height: 0 !important;
  color: white;
  overflow: hidden;
  background-color: white;
  transition: 0.2s;
}

.button-text-query {
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  position: absolute;
  text-align: left;
  display: flex;
  align-items: center;
}

.manage-search-icon {
  font-size: 40px !important;
  margin-left: 15px !important;
  margin-right: 10px !important;
}

.manage-search-icon-text {
  font-size: 15px !important;
  font-weight: bold !important;
}

.button-query-hover {
  width: 50px !important;
  height: 60px !important;
  border-radius: 25px !important;
  overflow: hidden;
  background-color: #9C27B0 !important;
  transition: 0.3s !important;
}

.button-query-hover:hover,
.button-query-hover:focus {
  width: 210px !important;
  background-color: #AA2AC1 !important;
  transition: 0.3s;
}

.elle-dark {
  background-color: #9C27B0 !important;
}

.button-box-open {
  width: 210px !important;
}

.menu-box-choose-text {
  height: auto;
  width: 1000px;
  padding: 20px;
  margin-top: 10px;
  border: solid #9C27B0 4px;
  overflow: hidden;
  border-radius: 25px;
  background-color: white;
  transition: 0.2s;
}

.menu-box-choose-text-closed {
  position: relative;
  height: 0;
  width: 0;
  overflow: hidden;
  border-radius: 25px;
  background-color: white;
  color: white;
  transition: 0.2s;
}

.filter-box-size {
  height: 100%;
  width: 80%;
  border: solid #9C27B0 4px;
  overflow: hidden;
  border-radius: 25px;
  background-color: white;
}

.query-find-texts-icon {
  font-size: 40px !important;
  margin-left: 10px;
  margin-right: 15px;
}

.query-find-texts-text {
  font-size: 15px;
  font-weight: bold;
}

.tools-chips {
  width: 100% !important;
}

.query-chip-button {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #9C27B0;
  background-color: #9C27B0;
  transition: 0.3s;
}

.query-chip-button::after {
  font-family: "Material Icons";
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25px;
  line-height: 40px;
  content: '\e0ee';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #9C27B0;
  opacity: 1;
  transition: 0.3s;
}

.query-chip-button:hover::after {
  opacity: 0;
  display: none;
  transition: 0.3s;
}

.query-chip-button:hover {
  position: relative;
  width: 260px;
  height: auto;
  padding: 10px;
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #9C27B0;
  background-color: white;
  transition: 0.3s;
}

.query-chip-header {
  width: 260px;
}
