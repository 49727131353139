@keyframes slidein {
  from {
    transform: translate(0, -50%);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes Overlay-slide {
  from {
    transform: translate(80%, -30%) rotate(20deg);
    opacity: 0;
  }

  to {
    transform: translate(40%, -30%) rotate(20deg);
    opacity: 1;
  }
}

@keyframes image-card-slide {
  from {
    transform: translate(100px);
    opacity: 0;
  }

  to {
    transform: translate(0px);
    opacity: 1;
  }
}

.home-title {
  text-align: center;
  margin-left: 20%;
  margin-bottom: 5%;
  padding-top: 4%;
  width: 60%;
}

.home-mockup-card {
  margin-left: 10%;
  margin-right: 10%;
}

.home-mockup-card-box {
  display: flex;
  width: 100%;
}

.home-mockup-card-textbox {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
}

.home-mockup-card-typography {
  padding: 10%;
  font-size: 1.3rem !important;
  text-align: center;
}

.home-summary-card {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 50px;
}

.home-summary-card-title-box {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.home-summary-card-title-typography {
  font-size: 30px !important;
  font-weight: bold;
}

.home-summary-card-content-box {
  display: flex;
  gap: 40px;
  width: 100%;
  padding: 40px;
}

.home-summary-card-content-box-inner-1 {
  height: auto;
  width: 25%;
}

.home-summary-card-content-box-inner-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.home-summary-card-avatar-box {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  width: 100%;
}

.home-summary-card-avatar {
  background-color: #9C27B0 !important;
}

.home-summary-card-content-textbox {
  display: flex;
  width: 100%;
}

.home-summary-card-content-typography {
  align-self: center;
  text-align: center;
}

.home-tabs-card {
  margin: 50px 10% 0;
}

.tab-button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 50px;
  font-weight: bold;
}

.picture-box {
  overflow: hidden;
  position: relative;
  border: solid 3px;
  border-top: none;
  width: 100%;
  border-radius: 5px;
}

.image-class {
  height: 800px;
  padding: 10px;
  animation-duration: 0.3s;
  animation-name: slidein;
  animation-timing-function: ease-out;
}

.image-container {
  overflow: hidden;
}

.image-card {
  outline: solid 5px;
  background: white;
  position: absolute;
  border-radius: 5px;
  top: 40%;
  right: 5%;
  width: 40%;
  height: 30%;
  animation-duration: 0.4s;
  animation-name: image-card-slide;
  animation-timing-function: ease-out;
}

.overlay {
  height: 1600px;
  transform-origin: left bottom;
  transform: translate(40%, -30%) rotate(20deg);
  animation-duration: 0.3s;
  animation-name: Overlay-slide;
  animation-timing-function: ease-out;
}


