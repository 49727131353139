.tool-page-container-outer {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 700px;
  background-color: white;
  border-radius: 45px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.tools-tab-overlay {
  position: relative;
}

.tools-tab-overlay::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, 1);
  z-index: 2;
}

.outer-container-tools {
  height: auto;
  background: fixed linear-gradient(315deg, rgba(156, 39, 176, 1) 0%, rgba(156, 39, 176, 1) 33%, rgba(204, 168, 253, 1) 33%, rgba(204, 168, 253, 1) 67%, rgba(255, 208, 253, 1) 67%, rgba(255, 208, 253, 1) 100%);
  border-radius: 50px;
  padding: 7px;
  margin-bottom: 50px;
}

.query-container {
  height: auto;
  z-index: 10;
}

.outer-outer {
  width: auto;
  z-index: 10;
}

.tools-box-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  width: 100%;
  height: auto;
}

.alert-max-width {
  max-width: 700px;
}

.cluster-tools-container {
  height: auto;
  width: 1000px;
  display: flex;
  justify-content: center;
}

.tool-card-container {
  width: 600px;
  height: 200px;
  margin: auto;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  border: solid 2px rgba(156, 39, 176, 1);
}

.tool-card-icon {
  padding: 10px;
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-card-text {
  padding: 10px;
  height: 100%;
  width: 60%;
  display: flex;
  align-items: center;
  font-size: 1.2em
}

.tool-icon-img {
  height: 80%;
}

.tool-title {
  text-align: center;
  padding-bottom: 2vh;
}

.tool-wrapper {
  margin-top: 5vh;
}

.tool-accordion {
  display: flex;
  justify-content: space-between;
}
