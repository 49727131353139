.collocates-analyse-button {
  margin-top: 7vh !important;
}

.collocates-search-count-textfield {
  width: 70px;
  margin-right: 0 !important;
}

.collocates-explanation {
  margin-left: 0.5vw !important;
  margin-top: 7px !important;
}
