@media (max-width: 925px) {
  .navBar-icon-button {
    display: flex !important;
  }

  .nav-menu-link-container {
    display: none;
  }

  .language-menu-desktop {
    display: none;
  }

  .nav-logout.desktop {
    display: none !important;
  }

  .elle-nav-logo {
    height: 30px !important;
  }
}

@media (min-width: 926px) {
  .navBar-icon-button {
    display: none !important;
  }

  .navbar-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .nav-menu-link-container {
    display: flex;
  }

  .language-menu-desktop {
    display: flex;
  }

  .elle-nav-logo {
    height: 35px !important;
  }
}

@media (max-width: 1334px) {
  .navbar-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1063px) {
  .nav-logout.desktop {
    .logout-text {
      display: none;
    }
  }

  .nav-menu-item {
    margin-left: 0 !important;
  }

  .nav-icons-container {
    width: 5% !important;
  }
}

.language-menu-desktop {
  align-items: center;
  justify-content: end;
}

.language-icon {
  color: black;
  cursor: pointer;
}

.nav-drawer-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.nav-logo-container {
  width: 5%;
}

.nav-50px-height {
  height: 50px !important;
}

.nav-menu-link-container {
  padding-left: 5%;
  width: 85%;
  justify-content: center;
  align-items: center;
}

.nav-icons-container {
  width: 17.5%;
  display: flex;
  justify-content: flex-end;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.burger-menu-icon {
  color: black;
  font-size: 45px !important;
}

.hover:hover {
  cursor: pointer;
}

.lang-icon {
  padding-right: 0.5em;
}

.sticking,
.not-sticking {
  position: sticky !important;
  top: 0 !important;
}

.sticking {
  background: transparent !important;
}

.not-sticking {
  background: linear-gradient(315deg, rgba(204, 168, 253, 1) 0%, rgba(204, 168, 253, 1) 9%, rgba(255, 208, 253, 1) 9%, rgba(255, 208, 253, 1) 22%, rgba(255, 255, 255, 1) 22%, rgba(255, 255, 255, 1) 100%);
  box-shadow: 0 0 5px 0 rgba(156, 39, 176, 0.5) !important;
}

.nav-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  line-height: 100%;
  text-align: center;
}

.nav-menu-item::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 10%;
  width: 100%;
  background-color: #9C27B0 !important;
  transform-origin: right;
  transition: 0.3s ease-in-out;
  scale: 0 1;
}

.nav-menu-item:hover::after {
  scale: 1 1;
}

.nav-close-icon {
  color: black !important;
  font-size: 45px !important;
}

.nav-logout {
  font-weight: 600;
  color: #1B1B1B;
  font-family: "Exo 2", sans-serif;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #9C27B0;
    cursor: pointer;
  }

  &:active {
    color: #9C27B0;
    cursor: pointer;
  }

  .logout-text {
    padding-left: 0.5rem;
  }
}
