.query-download-button-span {
  margin-bottom: 1em;
  float: right;
  margin-top: 0.7em;
}

.query-download-modal-button {
  height: 2.6em;
}

.query-download-dialog {
  width: 300px;
}

.query-download-modal-radio-group {
  margin-top: 1em !important;
  margin-left: 0.25em !important;
}
