.filterDiv {
  position: fixed;
  width: 180px;
  left: 20%;
  top: 100px;
  z-index: 1;
  background-color: white;
}

.analyserTable {
  margin-right: auto;
  margin-left: auto;
  border-bottom: solid 1px;
  width: 100%
}

.filter-class {
  width: 330px;
  min-width: 330px
}

.accordion-class {
  border: none;
  box-shadow: none;
  width: 68px;
  height: 48px
}

.accordion-button {
  height: 48px;
  width: 68px
}

.tableRow {
  background-color: white;
}

.tableRow:nth-child(even) {
  background-color: #EBECF0;
}

.tableHead {
  padding-left: 10px;
  border-bottom: solid 1px !important;
  color: black;
  font-weight: bold
}

.popover-box {
  padding: 10px;
}

.filter-container {
  display: flex;
  gap: 10px;
}

.tableHead:hover {
  cursor: auto;
}

.tableHead:nth-child(2) > .sortIcon {
  display: none;
}

.tableHead:nth-child(3) > .sortIcon {
  display: none;
}
