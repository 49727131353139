.services-container {
    width: 100%;
    height: 300px;
    display: flex;
    gap: 50px;
}

.service-detail {
    border-radius: 10px;
}

.service-container-outer {
    border-radius: 50px;
    margin-top: 100px;
    text-align: center;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(180deg, rgba(255, 208, 253, 0.7) 0%, rgba(255, 255, 255, 0) 50%);
}

.service-title-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 200px;
    background-color: rgb(255, 208, 253);
}

.service-text {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10% 10% 0;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(0deg, rgba(252, 245, 255, 1) 0%, rgba(255, 255, 255, 1) 50%);
    border-radius: 5px;
}

.service-detail:first-child {
    border-radius: 50px 10px 10px 50px;
}

.service-detail:first-child .service-text {
    border-radius: 45px 5px 5px 45px;
}

.service-detail:last-child {
    border-radius: 10px 50px 50px 10px;
}

.service-detail:last-child .service-text {
    border-radius: 5px 45px 45px 5px;
}