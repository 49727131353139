.tableRow:nth-child(even) {
  background-color: #f2f2f2;
}

.tableRow:hover {
  background-color: #ddd;
}

.fontStyle {
  font-size: large;
}

.tableHeader:hover {
  cursor: auto;
}

.tableHeader:nth-child(2) > .sort {
  display: none;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}
