.correction-container-outer {
  border-radius: 20px;
}

.correction-container {
  background: white;
  border-radius: 15px;
  padding: 1.5em 3em;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
