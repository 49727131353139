.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: baseline;
}

.pagination-button-group {
    display: flex;
    border-radius: 15px !important;
}

.fontStyle {
    font-size: large;
}

.pagination-textarea {
    width: 100px;
}

.buttongroup {
    width: 25%;
}

.table-pagination-button {
    border-right: solid 1px white !important;
}
