.download-license-and-button {
  padding-top: 1em;
  display: flex;
  justify-content: center;
}

.download-dialog {
  width: 300px;
  height: 170px;
}

.download-dialog-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.csvLink {
  color: white;
  text-decoration: none;
}

.csvLink:hover {
  color: white;
  text-decoration: none;
}
