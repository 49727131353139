.statistics-container {
  display: grid;
  width: 100%;
  height: 300px;
  margin-top: 100px;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 50px;
}

.statistics-inner-container {
  border-radius: 10px 10px 10px 10px;
}

.statistics-inner-container:last-child {
  border-radius: 10px 50px 50px 10px;
}

.statistics-inner-container:last-child div {
  border-radius: 5px 45px 45px 5px;
  padding: 10%;
}

.statistics-inner-container:first-child {
  border-radius: 50px 10px 10px 50px;
}

.statistics-inner-container:first-child div {
  border-radius: 45px 5px 5px 45px;
}

.statistics-box-inner {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.statistics-box-inner p {
  font-size: 20px;
}

.statistics-icon {
  font-size: 50px !important;
  color: #9C27B0;
  margin-bottom: 25px;
}
